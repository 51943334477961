import {validatePhoneNumberLength} from 'libphonenumber-js'
import parsePhoneNumber from 'libphonenumber-js/mobile'

export const validPhone = (v: any) => !!parsePhoneNumber(String(v))?.isValid()

export const validDate = (v: any) => {
  const dobRegex = /^(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[0-2])\.(19|20)\d{2}$/;

    if (!dobRegex.test(v)) {
        return false; // Дата не соответствует формату DD/MM/YYYY
    }

    const parts = v.split('.');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    const currentDate = new Date();
    const inputDate = new Date(year, month - 1, day); // Месяцы в JavaScript начинаются с 0

    if (inputDate > currentDate) {
        return false;
    }

    return true;
}

export const validPhoneLength = (v: any) => validatePhoneNumberLength(String(v), 'RU')

